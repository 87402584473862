.profile__edit-btn {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background-size: 24px;
  transition: 0.3s ease;
  background-image: url(../../../images/edit.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: #fff 1px solid;
  cursor: pointer;
}

.profile__edit-btn:hover {
  opacity: 0.6;
}

@media (max-width: 480px) {
  .profile__edit-btn {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }
}