.popup__caption {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #fff;
  margin: 10px 0 0 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .popup__caption {
    font-size: 14px;
  }
}

@media (max-width: 720px) {
  .popup__caption {
    font-size: 12px;
  }
}