.element__print-counter-group {
  display: flex;
  align-items: center;
  outline: 3px #777 solid;
  border-radius: 30px;
  padding: 0 7px;
  height: 25px;
  transition: .5s ease;
}

.element__print-counter-group:hover {
  outline-color: #000;
  transition: .5s ease;
}