.preloader {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(5px);
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preloader__container {
  display: flex;
  justify-content: center;
  width: 120px;
  height: 120px;
  position: relative;
}