.header__group {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.header__link-content {
  display: flex;
  align-items: center;
  text-wrap: nowrap ;
}

.header__icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

@media (max-width: 540px) {
  .header__group {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .header__group {
    gap: 0px;
  }
}

@media (min-width: 1280px) {
  .header__group {
    margin-right: 20px;
  }
}