.element__print-counter {
  margin: 0;
  padding: 0;
  display: inline;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 29px;
  min-width: 30px;
  text-align: center;
  min-height: 29px;
}