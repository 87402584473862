.authentication__submit {  
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 18px;
  width: 100%;
  color: #fff;
  height: 50px;
  padding: 0;
  border: 2px #fff solid;
  margin: 100px 0 0 0;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  transition: .7s ease;
  border-radius: 30px;
}

.authentication__submit:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transition: .7s ease;
}

@media (max-width: 768px){
.authentication__submit {
  font-size: 14px;
}
};