.header__e-mail {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  color: #fff;
  transition: .7s ease;
}

@media (max-width: 768px) {
  .header__e-mail {
    font-size: 14px;
  }
}