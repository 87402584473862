.element__heart-btn {
  background-image: url(../../../images/checkbox-blank-circle-svgrepo-com.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size:contain;
  background-color: transparent;
  width:25px;
  height:25px;
  border: none;
  scale: 1.25;
  transition: .5s ease-out;
  cursor: pointer;
  background-position: center;
  opacity: .55;
  outline: none;
}

.element__heart-btn:hover {
  opacity: 1;
}
