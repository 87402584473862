.popup__submit-btn {
  margin: 16px 0;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  background: #000;
  color: #fff;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  transition: .7s ease;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.popup__submit-btn:hover {
  background-color: #202020;
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 640px) {
  .popup__submit-btn {
    height: 46px;
    font-size: 14px;
    line-height: 17px;
    margin: 15px 0 0 0;
  }
}