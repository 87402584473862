.authentication__input {
  margin: 0 0 30px 0;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  padding: 0;
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 0;
  outline: 2px solid #fff;
  box-sizing: border-box;
  transition: .7s ease;
  border-radius: 15px;
  padding-left: 10px;
}

.authentication__input:last-of-type {
  margin: 0;
}

.authentication__input:focus {
  background-color: rgba(255, 255, 255, 0.3);
  transition: .7s ease;
}

.authentication__input::placeholder {
  color: #fff;
  opacity: .5;
  }
