.element__image {
  width: 282px;
  height: 282px;
  object-fit: cover;
  border-radius: 9px 9px 0 0;
  cursor: pointer;
}

.landscape {
  object-position: center;
}

.portrait {
  object-position: 0px -25px;
}