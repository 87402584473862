.tech {
  background-color: #272727;
  border-radius: 30px;
  padding: 100px calc((100% - 986px) / 2);
  color: #fff;
}

.tech__title {
  margin: 0;
  padding: 0 0 25px;
  font-size: 22px;
  line-height: normal;
  letter-spacing: -0.88px;
  font-weight: 400;
  border-bottom: 1px solid #dadada;
}

.tech__subtitle {
  margin: 0;
  padding: 90px 0 26px;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  font-weight: 400;
  letter-spacing: -2px;
}

.tech__text {
  margin: 0 auto;
  padding: 0 0 100px;
  text-align: center;
  letter-spacing: -0.56px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 460px;
}

.tech__list {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  list-style-type: none;
  max-width: 690px;
  width: 100%;
}

.tech__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  min-height: 60px;
  text-align: center;
  letter-spacing: -0.56px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  background-color: #303030;
}

@media (max-width: 1024px) {

  .tech__subtitle {
    padding: 82px 0 19px;
  }

  .tech {
    padding: 92px 50px;
  }

  .tech__text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
    padding-bottom: 85px;
  }

  .tech__item {
    min-width: 84px;
    min-height: 57px;
    font-size: 12px;
  }

  .tech__list {
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .tech {
    padding: 70px 14px;
  }

  .tech__title {
    letter-spacing: -0.72px;
    font-size: 18px;
  }

  .tech__text {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }

  .tech__subtitle {
    font-size: 30px;
    letter-spacing: -1.2px;
  }

  .tech__list {
    flex-wrap: wrap;
  }

  .tech__item {
    font-size: 12px;
    letter-spacing: -0.48px;
    width: 84px;
    height: 57px;
  }
}

@media (max-width: 400px) {
  .tech__title {
    padding-bottom: 28px;
  }
  .tech {
    padding: 80px 18px;
    background-color: #202020;
  }

  .tech__list {
    justify-content: flex-start;
    max-width: 178px;
  }

  .tech__subtitle {
    padding: 49px 0 12px;
  }

  .tech__text {
    padding-bottom: 50px;
  }
}