.authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0 50px;
  width: 300px;
  margin: 0px auto;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, .1);
  color: #fff;
}

@media (max-width: 400px){
  .authentication {
    padding: 20px 0 20px;
  }
}

@media (max-height: 480px){
  .authentication {
    padding: 30px 0 25px;
    scale: .7;
  }
}