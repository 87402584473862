.page {
  background-color: transparent;
  height: 100vh;
  max-width: 1280px;
  margin: 0 auto;
}

.body {
  background-color: #000;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.body_colorful {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}