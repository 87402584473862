.header__link {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-decoration: none;
  transition: opacity .4s ease-in-out;
  color: #fff;
  transition: .7s ease;
  margin-left: 20px;
}

.header__link:hover {
  opacity: 0.6;
}

@media (max-width: 1024px) {
  .header__link {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .header__link {
    margin-left: unset;
    font-size: 12px;
    padding-bottom: 5px;
  }
}