.authentication__link {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  padding: 15px 0 0 0;
  transition: opacity .4s ease-in-out;
 
} 

.authentication__link:hover {
  opacity:0.6;
}