.element {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  max-width: 282px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  transition: .5s ease;
}

.element:hover {
  transition: .5s ease;
  box-shadow: 0px 0px 29px 8px rgba(0, 0, 0, 0.95); 
  scale: 1.005;
  z-index: 1;
}

/* @media (max-width:768px) {
  .element:hover {
    scale: 1.005;
  }
}

@media (max-height:768px) {
  .element:hover {
    scale: 1.005;
  }
} */