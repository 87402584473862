.header {
  padding: 15px 20px;
  display: flex;
  justify-content:space-between;
  border-bottom: 1px solid #dadada;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(30px);
  z-index: 2;
  width: 100%;
  align-items: center;
}

@media (max-width: 480px) {
  .header {
    padding: 10px 0px 3px 16px;
    max-width: 100%;
  }
}

.header_noborder {
  display: none;
}

@media (min-width: 1280px) {
  .header {
    max-width: 1240px;
  }
}

@media (max-height: 480px) {
  .header {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}