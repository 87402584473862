.popup__close-btn {
  position: absolute;
  background-image: url(../../../images/close.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-position: center;
  z-index: 3;
  right: -50px;
  top: -40px;
  transition: .3s ease;
  border: 0;
  cursor: pointer;
}

.popup__close-btn:hover {
  opacity: .6;
}



@media (max-height: 720px) {
  .popup__close-btn {
    top: -3px;
    right: -46px;
    scale: .75;
  }
}

@media (max-width: 640px) {
  .popup__close-btn {
    top: -40px;
    right: -4px;
    scale: .75;
  }
}