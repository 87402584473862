.element__heart-btn_active {
  background-image: url(../../../../images/checkbox-circle-svgrepo-com.svg);
  opacity: 1;
  animation: pulse .5s;
}


@keyframes pulse {
  0% {
      transform: scale(1.3);
  }
  50% {
      transform: scale(0.7);
  }
  100% {
      transform: scale(1);
  }
}