.popup {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  transition: visibility .5s, opacity .5s ease;
  z-index: 3;
}

.popup__textarea {
  box-sizing: border-box;
  resize: none;
  width: 100%;
  outline: none;
  height: 28px;
}

.popup__text-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}