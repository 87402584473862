.footer__copyright {
  margin: 0;
  padding: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #545454;
}

@media (max-width: 480px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}