.popup__error {
  padding: 0;
  display: block;
  font-family: 'Inter', Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #f00;
  min-height: 30px;
  max-width: 358px;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .popup__error {
    width: 238px;
  }
}