.popup__container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 22px 36px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  max-width: 430px;
}

@media (max-width: 640px) {
  .popup__container {
    min-width: 282px;
    padding: 22px;
  }
}

@media (max-height: 481px) {
  .popup__container {
    scale: .55;
  }
}