.elements {
  display: grid;
  grid-template-columns: repeat(auto-fit, 282px);
  justify-content: center;
  gap: 20px 17px;
  padding: 40px calc((100% - 986px) / 2) 70px;
  min-height: 30vh;
  background: url('../../images/background.jpg');
  background-size: 500px;
  background-attachment: fixed;
  border-radius: 30px;
}

@media (max-width: 1024px) {
  .elements {
    padding-left: 19px;
    padding-right: 19px;
  }
}

@media (max-width: 633.5px) {
  .elements {
    padding-top: 20px;
  }
}

@media (max-width: 480px) {
  .elements {
    padding-left: 0;
    padding-right: 0;
  }
}