.profile__image {
  height: 100%;
  width: 100%;
  object-position: 0px -10px;
  object-fit: cover;
  transition: opacity 0.4s ease;
  border-radius: 50%;
}

.profile__image:hover {
  opacity: 0.2;
  cursor: pointer;
}