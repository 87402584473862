.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 170px calc((100% - 986px) / 2) 40px;
}

@media (max-width: 1024px) {
  .profile {
    padding-left: 19px;
    padding-right: 19px;
  }
}

@media (max-width: 768px) {
  .profile {
    flex-direction: column;
  }
}