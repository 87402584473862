.element__options {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 10px;
  height: 180px;
}