.footer {
  /* padding: 60px calc((100% - 986px) / 2); */
  padding: 36px 19px;
  transition: .7s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) {
  .footer {
    padding: 36px 19px;
  }
}

@media (max-width: 580px) {
  .footer {
    justify-content: center;
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
  }

  .footer__logo {
    padding-bottom: 20px;
  }

  .footer__link {
    text-align: center;
  }
}

.footer__link {
  text-decoration: none;
  transition: .7s ease;
}

.footer__link:hover {
  opacity: 0.8;
}

.footer__logo {
  width: 154px;
  opacity: .3;
}