.port {
  padding: 100px 0 120px;
}

.port__title {
  margin: 0 0 55px;
  padding: 0;
  color: #8b8b8b;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.port__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  height: 230px;
  padding: 0;
  list-style: none;
}

.port__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #424242;
  height: 50px;

}

.port__item:last-child {
  margin: 0;
  padding: 0;
  border-bottom: none;
}

.port__link {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #fff;
  font-size: 30px;
  letter-spacing: -1.2px;
  font-weight: 400;
  line-height: 50px;
  transition: 0.4s;
  width: 100%;
}

.port__link:hover {
  opacity: 0.7;
}

.port__name {
  margin: 0;
  padding: 0;
}

.port__icon {
  line-height: 60px;
}

@media (max-width: 770px) {
  .port {
    padding: 90px 0 0;
  }

  .port__link {
    font-size: 28px;
  }

  .port__item {
    padding-bottom: 12px;
  }
}

@media (max-width: 400px) {
  .port {
    padding-top: 70px;
  }

  .port__title {
    margin: 0 0 30px;
    font-size: 14px;
  }

  .port__link {
    font-size: 18px;
    letter-spacing: -0.72px;
    line-height: 28px;

  }

  .port__icon {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }

  .port__list {
    height: 184px;
  }

  .port__item {
    padding-bottom: 6px;
  }
}