  .header__logo {
    display: flex;
    width: 164px;
    height: 77px;
    transition: .7s ease;
  }

  .header__logo:hover {
    opacity: .6;
  }

  @media (max-width: 480px) {
    .header__logo {
      width: 118px;
      height: 55px;
    }
  }

  .header__logo-horizontal {
    display: none;
  }

  @media (max-height: 480px) {
    .header__logo {
      display: none;
    }
    .header__logo-horizontal-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;

    }
    .header__logo-horizontal {
      display: flex;
      height: 30px;
    }
  }