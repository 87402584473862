/* * {
  outline: 1px solid tomato;
} */
.account {
  padding: 230px calc((100% - 986px) / 2) 100px;
  background-color: #303030;
}

.account__title {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  
  color: #fff;
}

.account__project {
  margin: 0;
  gap: 40px;
  padding: 70px 0 0;
  display: grid;
  list-style: none;
  grid-template-columns: auto auto;
  color: #fff;
}

.account__project-item {
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-width: 550px;
}

.account__project-subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.account__project-text {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.account__week {
  display: grid;
  gap: 5px 0;
  grid-template-columns: 1fr 4fr;
  padding-top: 40px;
}

.account__week-style {
  margin: 0;
  align-items: center;
  display: flex;
  height: 36px;
  width: 100%;
}

.account__week-subtitle {
  background-color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.account__week-subtitle_mod {
  margin: 0;
  width: 110px;
  height: 35px;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  transition: 0.4s;
  opacity: 1;
  box-sizing: border-box;
  background: none;
  color: #fff;
  border: none;
  outline: 1px solid #fff;
}

.account__week-subtitle_mod:hover {
  opacity: 0.8;
  cursor: pointer;
}

.account__week-description {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  justify-content: center;
}

@media (max-width: 1024px) {
  .account {
    padding: 230px 50px 81px;
  }

  .account__project {
    gap: 33px;
  }

  .account__project-item {
    gap: 22px;
  }

  .account__project-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-style: normal;
  }

  .account__week {
    gap: 3px 0;
    grid-template-columns: 1.06fr 4fr;
    padding-top: 93px;
  }
}

@media (max-width: 600px) {
  .account {
    padding: 170px 18px 70px;
  }

  .account__title {
    padding: 0 0 28px;
    font-size: 18px;
    font-weight: 400;
  }

  .account__project-subtitle {
    font-size: 20px;
  }

  .account__project-text {
    font-size: 14px;
    line-height: 16px;
  }

  .account__project {
    gap: 50px;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .account {
    padding-top: 120px;
  }

  .account__project-subtitle {
    font-size: 18px;
  }

  .account__project {
    gap: 56px;
    padding-top: 60px;
  }

  .account__project-item {
    gap: 16px;
  }

  .account__week {
    gap: 11px 0;
    grid-template-columns: 2.2fr 4fr;
    grid-template-rows: 23px 15px;
    padding-top: 59px;
  }

  .account__week-subtitle {
    font-size: 11px;
  }

  .account__week-description {
    font-size: 11px;
  }
}