.profile__avatar {
  width: 120px;
  height: 120px;
  margin-right: 30px;
  border-radius: 50%;
  object-fit: cover;
  transition: opacity .4s ease;
  background: url(../../../images/pen.svg) #000 no-repeat 50%;
  
}


@media (max-width: 768px) {
  .profile__avatar {
    margin: 0;
  }
}