.profile__add-btn {
  border: #fff solid 2px;
  min-width: 75px;
  height: 50px;
  transition: .3s ease;
  background-image: url(../../../images/add.svg);
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  border-radius: 2px;
}

.profile__upload-btn {
  border: #fff solid 2px;
  min-width: 75px;
  height: 50px;
  transition: .3s ease;
  background-image: url(../../../images/upload2.svg);
  background-size: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 20px;
}

.profile__add-btn:hover {
  opacity: .6;
}

.profile__upload-btn:hover {
  opacity: .6;
}

@media (max-width: 768px) {
  .profile__add-btn {
    margin: 0;
    padding: 0;
    margin-top: 34px;
    width: 282px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 16px;
  }

  .profile__upload-btn {
    margin: 0;
    padding: 0;
    margin-top: 34px;
    width: 282px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 24px;
  }
}