.popup__title {
  margin: 3px 0 50px;
  padding: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000;
}

@media (max-width: 640px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
    margin: 2px 0 50px 0;
  }
}