.stud {
  padding: 112px calc((100% - 986px) / 2) 0;
  color: #fff;
}

.stud__title {
  margin: 0;
  padding: 0 0 23px;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.88px;
  line-height: normal;
  border-bottom: 1px solid #dadada;
}

.stud__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 66px 0 0;
}

.stud__name {
  margin: 0;
  padding: 0;
  font-size: 50px;
  line-height: 58px;
  font-weight: 400;
  letter-spacing: -2px;
}

.stud__job {
  margin: 0;
  padding: 18px 0 26px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.stud__info {
  margin: 0;
  padding: 0 49px 99px 0;
  max-width: 599px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.stud__link {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  line-height: normal;
  font-weight: 500;
  transition: .7s ease;
}

.stud__link:hover {
  opacity: 0.6;
}

.stud__photo {
  display: block;
  height: 327px;
  width: 270px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-self: flex-start;
}

@media (max-width: 1024px) {
  .stud {
    padding: 88px 50px;
  }

  .stud__name {
    font-size: 40px;
    letter-spacing: -1.6px;
    line-height: 40px;
  }

  .stud__job {
    font-size: 12px;
    padding-bottom: 20px;
    line-height: 18px;
  }

  .stud__info {
    padding-bottom: 86px;
    padding-right: 49px;
    font-size: 12px;
    line-height: 18px;
    max-width: 360px;
  }

  .stud__photo {
    height: 307px;
    width: 255px;
  }
}

@media (max-width: 600px) {
  .stud {
    padding: 70px 14px;
  }

  .stud__wrapper {
    display: flex;
    padding-top: 60px;
    gap: 40px;
    align-items: center;
    flex-direction: column-reverse;
  }

  .stud__photo {
    min-width: 292px;
    align-self: center;
    min-height: 352px;
  }
}

@media (max-width: 400px) {
  .stud {
    padding: 60px 14px;
  }

  .stud__title {
    font-size: 18px;
    padding-bottom: 28px;
    letter-spacing: -0.72px;
  }

  .stud__name {
    letter-spacing: -1.2px;
    font-size: 30px;
  }

  .stud__job {
    padding-top: 15px;
    font-size: 11px;
  }

  .stud__info {
    padding-bottom: 38px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
  }
}