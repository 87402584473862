.popup__image {
  object-fit: contain;
  max-height: 82vh;
  max-width: 82vw;
}

.popup__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  background-color: rgba(184, 184, 184, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease, transform 0.4s ease;
  outline: none;
}

.popup__nav-button:hover {
  background-color: rgba(184, 184, 184, 0.7);
  transform: translateY(-50%) scale(1.1);
}

.popup__nav-button:active {
  background-color: rgba(202, 202, 202, 0.9);
  filter: drop-shadow(0 0 10px white);
}

.popup__nav-button--prev {
  left: -56px;
}

.popup__nav-button--next {
  right: -62px;
}

@media (max-width:640px) {
  .popup__nav-button--prev {
    left: 10px;
  }

  .popup__nav-button--next {
    right: 10px;
  }
}

@media (max-width:480px) {
  .popup__nav-button {
    display: none;
  }
}