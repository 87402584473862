.popup__input {
  margin: 0;
  padding: 0;
  width: 358px;
  min-height: 30px;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  outline: none;
  transition: .7s ease;
}

@media (max-width: 640px) {
  .popup__input {
    width: 238px;
  }
}

.popup__input:focus {
  border-bottom: 1px solid #23a6d5;
  transition: .7s ease;
}