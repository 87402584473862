.popup__wrapper {
  display: flex;
  justify-content: space-between;
}

.popup__input_field_checkbox {
  width: 25px;
}

.popup__label {
  min-height: 30px;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  box-sizing: border-box;
  border: none;
  outline: none;
}